<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 font-weight-medium">
          App manager
        </h3>
        <p class="text-justify ma-0">
          App Manager es un centro de ayuda especialmente para reportar
          problemas ocasionados en la app, con ello buscamos dar soporte y estar
          en una mejora continua para brindar la mejor experiencia en el uso de
          la app.
        </p>
      </v-col>
      <v-col cols="12">
        <h3 class="text-h5 mb-3 text-center">Notas</h3>
        <h3 class="text-h6">V 0.1.1</h3>
        <p class="caption">
          Las notas de la versión 0.1.1 contiene mejoras de rendimiento y
          optimización en los procesos internos de la app, se añadieron nuevas
          características:
        </p>
        <ul>
          <li>Multi database</li>
          <li>Estatus del usuario (Online/Offline)</li>
          <li>Real-time registro de inventarios</li>
          <li>App nativa</li>
        </ul>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Envía un reporte
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref="debug">
                <v-text-field
                  :rules="required"
                  v-model="form.subject"
                  outlined
                  label="Asunto"
                ></v-text-field>
                <v-textarea
                  :rules="required"
                  v-model="form.detail"
                  outlined
                  rows="3"
                  label="Detalles"
                ></v-textarea>
                <v-file-input
                  outlined
                  accept="image/*"
                  label="Adjunta una captura"
                ></v-file-input>
                <div class="text-center">
                  <v-btn :loading="loader" color="primary" @click="sendReport"
                    >Enviar info</v-btn
                  >
                </div>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-footer app>
      <v-container>
        <div class="d-flex justify-center align-center">
          <div class="d-flex">
            <p class="ma-0">SSENCIAL</p>
            <p class="ma-0 caption">©</p>
          </div>
          <span class="ml-2 caption">V {{ appVersion }}</span>
        </div>
      </v-container>
    </v-footer>
  </v-container>
</template>

<script>
import { required } from "@/services/ValidatorService";
import { firestore } from "@/config/firebase";
import { showMessage } from "@/services/MessageService";
import moment from "moment";
import { mapState } from "vuex";
import { app } from "@/config/globals";

export default {
  data() {
    return {
      appVersion: app.version,
      required: [required],
      form: {
        subject: null,
        detail: null
      },
      loader: false
    };
  },
  computed: {
    ...mapState("user", ["data"]),
    ...mapState("business", ["currentBranchID"])
  },
  methods: {
    async sendReport() {
      if (this.$refs.debug.validate()) {
        this.loader = true;
        const bugDocument = await firestore("bugs").doc();
        const bugID = bugDocument.id;
        await bugDocument.set({
          id: bugID,
          business: this.currentBranchID,
          userID: this.data.uid,
          subject: this.form.subject,
          detail: this.form.detail,
          created: moment().unix()
        });
        this.loader = false;
        this.form = {
          subject: null,
          detail: null
        };
        this.$refs.debug.reset();
        showMessage({
          message: "Gracias por enviar tu reporte",
          color: "success"
        });
      }
    }
  }
};
</script>

<style></style>
